import React from 'react'
import {
    Grid,
    Button,
    Dialog,
    Typography,
    DialogActions,
    DialogTitle,
    DialogContent
} from '@mui/material';
import history from '../../history';

export default function GuideInformation({ guides, open, onClose }) {
    return (
        <Dialog
            open={open}
            onClose={() => {
                onClose();
                history.push('/home');
            }}
        >
            <DialogTitle>Información de la Guia</DialogTitle>
            <DialogContent>
                {guides.map(guide => (
                    <Grid
                        key={guide.guideId}
                        container
                        direction="column"
                        spacing={2}
                        justifyContent="center"
                        style={{ marginTop: 20 }}
                    >
                        <Grid item container direction="row" spacing={2}>
                            <Grid item>
                                <Typography>Id de guía: {guide.guideId}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography>Número de guía: {guide.guideNo}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container direction="row" justifyContent="center" spacing={2}>
                            <Grid item>
                                <Typography>
                                    <a href={guide.pdf} target="_blank" rel="noreferrer">
                                        Puedes consultar el pdf aquí
                                    </a>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    onClose();
                    history.push('/home');
                }}>OK</Button>
            </DialogActions>
        </Dialog>
    )
}
