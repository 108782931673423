import { http } from '../utils/http';

const GuideAPI = {
    async getQuotations({ packages, address }) {
        try {
            const response = await http.request({
                url: `/skydrop/quotation`,
                method: 'POST',
                data: {
                    packages,
                    zipCodes: {
                        from: '76116',
                        to: address.zipCode,
                    },
                },
            });

            return response.data;
        } catch (error) {
            console.error(error.message);
            console.log(error.config);
            if(error.response){ 
                console.log(error.response);
                return {error: {message: error.response.data.message || `${error.response.status} ${error.response.statusText}`}};
            } else {
                console.log(error.request);
                return { error: { message: "No se pudo realizar la petición" } };
            }
        }
    },
    async getGuide(shipmentDTO) {
        try {
            const response = await http.request({
                url: '/skydrop/shipment',
                method: 'POST',
                data: { ...shipmentDTO }
            });
            return response.data;
        } catch (error) {
            console.error(error.message);
            console.log(error.config);
            if(error.response){ 
                console.log(error.response);
                return {error: {message: error.response.data.message || `${error.response.status} ${error.response.statusText}`}};
            } else {
                console.log(error.request);
                return { error: { message: "No se pudo realizar la petición" } };
            }
        }
    }
}

export default GuideAPI;
