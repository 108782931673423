import { createTheme } from "@mui/material";

export default createTheme({
    palette: {
        primary: {
            main: '#6402FE'
        },
        secondary: {
            main: '#465667',
            light: '#635DFF'
        }
    },
});