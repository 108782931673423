import React from 'react';
import { ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';

import AppRouter from './components/AppRouter';
import theme from './theme';

function App() {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <AppRouter />
        </SnackbarProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;