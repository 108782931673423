import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { FormSkeleton } from '../components/shared';
import { GuideForm } from '../components/guides';
import { OrderAPI } from '../api';

function GuideCreate() {
    const { id } = useParams();
    const [orderData, setOrderData] = useState(null);
    const [loading, setLoading] = useState(false);

    const onSubmit = (data) => console.log(data);

    useEffect(() => {
        (async () => {
            setLoading(true);
            const order = await OrderAPI.getOrder(id);
            setLoading(false);

            if (order) {
                setOrderData(order);
            }
        })();
    }, [id]);

    if (loading) return <FormSkeleton />;

    return (
        <div>
            <Typography style={{ textAlign: 'center' }} variant="h5">Orden de venta: {orderData?.order?.orderId}</Typography>
            {!orderData ? (
                <p>No se pudieron cargar los datos de la orden de venta</p>
            ) : (
                <GuideForm
                    initialData={{ ...orderData, serviceId: '' }}
                    onSubmit={onSubmit}
                />
            )}
        </div>
    )
}

export default GuideCreate;