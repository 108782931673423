import React from 'react'
import { Grid } from '@mui/material';
import { Skeleton } from '@mui/material';

function FormSkeleton() {
    return (
        <Grid container direction="column" spacing={2}>
            <Grid item container justifyContent="center">
                <Grid item xs={3}>
                    <Skeleton variant="text" />
                </Grid>
            </Grid>
            <Grid item container>
                <Grid item xs={3}>
                    <Skeleton variant="text" />
                </Grid>
            </Grid>
            <Grid item container>
                <Grid item xs={3}>
                    <Skeleton variant="rectangular" width={80} height={30} />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Skeleton variant="rectangular" height={120} />
            </Grid>
        </Grid>
    )
}

export default FormSkeleton;