import React from 'react'
import { Grid } from '@mui/material';
import { InputField } from '../inputs';

const ClientFields = ({ control }) => {
    return (
        <>
            <Grid container direction='row' spacing={2} >
                <Grid item>
                    <InputField
                        name='client.name'
                        label='Nombre'
                        type='text'
                        fullwidth
                        required
                        control={control}
                    />
                </Grid>
                <Grid item>
                    <InputField
                        name='client.ftName'
                        label='Apellido paterno'
                        type='text'
                        fullwidth
                        required
                        control={control}
                    />
                </Grid>
                {/* <Grid item>
                    <InputField
                        name='client.ltName'
                        label='Apellido materno'
                        type='text'
                        fullwidth
                        control={control}
                    />
                </Grid> */}
                <Grid item>
                    <InputField
                        name='client.principalPhone'
                        label='Teléfono principal'
                        type='text'
                        fullwidth
                        required
                        control={control}
                    />
                </Grid>
                <Grid item>
                    <InputField
                        name='client.secondaryPhone'
                        label='Teléfono secundario'
                        type='text'
                        fullwidth
                        control={control}
                    />
                </Grid>
                <Grid item>
                    <InputField
                        name='client.principalEmail'
                        label='Email principal'
                        type='text'
                        fullwidth
                        required
                        control={control}
                    />
                </Grid>
                <Grid item>
                    <InputField
                        name='client.firstContact'
                        label='Primer contacto'
                        type='text'
                        fullwidth
                        control={control}
                    />
                </Grid>
                {/* <Grid item>
                    <InputField
                        name='client.company'
                        label='Empresa'
                        type='text'
                        fullwidth
                        control={control}
                    />
                </Grid> */}
            </Grid>
        </>
    )
}

export default ClientFields;