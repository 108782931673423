import React, { useState, useEffect } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import PackageForm from './PackageForm';
import PackageList from './PackageList';
import EditProdToShipForm from './ProdToShipForm';
import ProdsToShipList from './ProdsToShipList';
import QuotationsList from './QuotationsList';
import { AddButton, AddressFields, ClientFields } from '../shared';
import { makeStyles } from '@mui/styles';
import { GuideAPI } from '../../api';
import { scroller, Element } from 'react-scroll';
import GuideInformation from './GuideInformation';

const useStyles = makeStyles({
    title: {
        marginBottom: 8,
    }
});

// const shippingMethods = [
//     { value: 'ocu', name: 'OCU' },
//     { value: 'ead', name: 'EAD' }
// ];

function GuideForm({ initialData, onSubmit }) {
    const { title } = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [openPackage, setOpenPackage] = useState(false);
    const [openEditShip, setOpenEditShip] = useState(false);
    const [quotations, setQuotations] = useState(null);
    const [initialValues, setInitialValues] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [guideData, setGuideData] = useState([]);
    const [result, setResult] = useState([]);
    const [open, setOpen] = useState(false);

    const {
        control,
        setValue,
        getValues,
        watch,
        handleSubmit,
    } = useForm({
        mode: 'onChange',
        defaultValues: initialData
    });

    const addPackage = (data) => {
        const prevPackages = getValues('packages');
        setValue('packages', [...prevPackages, { id: uuidv4(), ...data }]);
        setOpenPackage(false);
    };

    const editPackage = (data) => {
        const newPackages = getValues('packages').map(p => {
            if (p.id === data.id) {
                return data;
            }
            return p;
        });
        setValue('packages', newPackages);
        setInitialValues(undefined);
        setOpenPackage(false);
    }

    const onCancelPackage = () => {
        setOpenPackage(false);
        if (initialValues) {
            setInitialValues(undefined);
        }
    }

    const onCancelProdToShip = () => {
        setOpenEditShip(false);
        if (initialValues) {
            setInitialValues(undefined);
        }
    }

    const editProdToShip = (data) => {
        if (!(data.shippingNow > data.pendingShip)) {
            const newProducts = getValues('prodsToShip').map(p => {
                if (p.id === data.id) {
                    return data;
                }
                return p;
            });
            setValue('prodsToShip', newProducts);
            setOpenEditShip(false);
        } else {
            enqueueSnackbar(
                'La cantidad de envío especificada supera el pendiente de envío',
                { variant: 'info' }
            );
        }
    }

    const getQuotations = async () => {
        try {
            const { packages, address } = getValues();

            setLoading(true);

            const response = await GuideAPI.getQuotations({ packages, address });
            const quotations = {};
            setResult(response.result);

            response.result.forEach((q) => {
                q.quotas.forEach((quota) => {
                    const { provider, total_pricing, service_level_code } = quota;
                    const total = quotations[provider]
                        ? quotations[provider].total + Number(total_pricing) :
                        Number(total_pricing);
                    const counter = quotations[provider] ? quotations[provider].counter + 1 : 1;
                    const isSameCode = quotations[provider]
                        ? !quotations[provider].isSameCode
                            ? false
                            : quotations[provider].service_level_code === service_level_code
                        : true;
                    quotations[provider] = {
                        total,
                        counter,
                        service_level_code,
                        isSameCode,
                    };
                });
            });

            setLoading(false);
            setQuotations(quotations);
            scroller.scrollTo('quotations-list', { duration: 1500, delay: 100, smooth: true });
        } catch (error) {
            console.log(error.message);
            enqueueSnackbar(
                'Ocurrio un error al cargar los datos',
                { variant: 'error', transitionDuration: 1500 }
            );
        } finally {
            setLoading(false);
        }
    }

    const createGuide = async (quota) => {
        const { order, address, client, prodsToShip } = getValues();
        const serviceQuot = result.map((q) => {
            const service = q.quotas.find(quot =>
                quot.service_level_code === quota.service_level_code
                && quot.provider === quota.provider);
            return { service, package: q.package };
        });

        const guides = await GuideAPI.getGuide({
            order,
            address,
            client,
            prodsToShip,
            serviceQuot,
        });

        if (guides.error) {
            enqueueSnackbar(
                guides.error.message,
                { variant: 'error', }
            )
            return;
        }

        setGuideData(guides);
        setOpen(true);
    }

    const packages = watch('packages');

    useEffect(() => {
        setQuotations(null);
    }, [packages]);

    return (<>
        <GuideInformation
            open={open}
            onClose={() => setOpen(false)}
            guides={guideData}
        />
        <PackageForm
            open={openPackage}
            addPackage={initialValues ? editPackage : addPackage}
            onCancel={onCancelPackage}
            initialValues={initialValues}
        />
        <EditProdToShipForm
            open={openEditShip}
            onCancel={onCancelProdToShip}
            saveProdToShip={editProdToShip}
            initialValues={initialValues}
        />
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <Typography variant="h5" className={title}>Paquetes</Typography>
                    <AddButton
                        onClick={() => setOpenPackage(true)}
                    />
                </Grid>
                <Grid item>
                    <PackageList
                        packagesList={watch('packages')}
                        setFieldValue={setValue}
                        setOpenPkgForm={(id) => {
                            const pkg = getValues('packages').find(p => p.id === id);
                            const { vol, ...rest } = pkg;
                            setInitialValues(rest);
                            setOpenPackage(true);
                        }}
                    />
                </Grid>
                {/* DESTINATION ADDRESS and CLIENT DATA SECTION */}
                <Grid item
                    container
                    direction='row'
                    spacing={0}
                >
                    {/* DESTINATION ADDRESS */}
                    <Grid item xs={6}>
                        <Typography
                            variant='h5'
                            className={title}
                        >Dirección de envío</Typography>
                        <AddressFields
                            control={control}
                        />
                    </Grid>
                    {/* CLIENT DATA SECTION */}
                    <Grid item xs={6}>
                        <Typography
                            variant='h5'
                            className={title}
                        >Cliente</Typography>
                        <ClientFields control={control} />
                    </Grid>
                </Grid>
                {/** SERVICE DATA */}
                {/* <Grid item>
                    <Typography
                        variant='h5'
                        className={title}
                    >Método de envío</Typography>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={3}>
                            <InputSelect
                                name="serviceId"
                                label="Método de envío"
                                control={control}
                                fullwidth
                                options={shippingMethods}
                            />
                        </Grid>
                    </Grid>
                </Grid> */}
                {/* PRODUCTS TO SHIP DATA */}
                <Grid item>
                    <Typography
                        variant='h5'
                    >Marcar como entregado</Typography>
                    <Typography color='primary'>
                        Edita o elimina los productos que estas entregando.
                    </Typography>
                    <ProdsToShipList
                        productsList={watch('prodsToShip')}
                        setValue={setValue}
                        setOpenProdForm={(id) => {
                            const prod = getValues('prodsToShip').find(p => p.id === id);
                            setInitialValues(prod);
                            setOpenEditShip(true);
                        }}
                    />
                </Grid>
                <Grid item>
                    <Typography variant='h5' className={title}>Acciones</Typography>
                    <Grid container direction='row' spacing={2}>
                        <Grid item>
                            <Button
                                variant='contained'
                                color='inherit'
                                type='submit'
                                disabled={loading}
                                onClick={getQuotations}
                            >Generar cotización</Button>
                        </Grid>
                    </Grid>
                </Grid>
                {quotations && (
                    <Grid item >
                        <Element name="quotations-list">
                            <Typography variant='h5' className={title}>Quotations</Typography>
                            <QuotationsList quotations={quotations} createGuide={createGuide} packagesNo={getValues('packages').length} />
                        </Element>
                    </Grid>
                )}
            </Grid>
        </form>
    </>
    )
}

export default GuideForm;