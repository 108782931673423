import React, { useState } from 'react';
import {
    Table,
    Paper,
    TableBody,
    TableRow,
    TableHead,
    TableCell,
    IconButton,
    TableContainer,
} from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { StyledTableCell, StyledTableRow } from '../shared';

const QuotationList = ({ quotations, createGuide, packagesNo }) => {

    const [load, setLoad] = useState(false);

    const generateGuide = async (quota) => {
        setLoad(true);
        await createGuide(quota);
        setLoad(false);
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }} size='small' aria-label="quotations table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Paquetería</StyledTableCell>
                        <StyledTableCell>Costo total</StyledTableCell>
                        <StyledTableCell>Generar Guía</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {quotations ? Object.keys(quotations).map((key) => {
                        const allQuoted = quotations[key].counter === packagesNo;

                        return (
                            <StyledTableRow key={key}>
                                <TableCell>
                                    {key}
                                    {allQuoted
                                        ? null
                                        : <span style={{ color: 'red' }}> No se cotizaron ambos paquetes</span>
                                    }
                                </TableCell>
                                <TableCell>{quotations[key].total}</TableCell>
                                <TableCell>
                                    <IconButton
                                        color='primary'
                                        aria-label='create guide'
                                        disabled={load || !allQuoted}
                                        onClick={() => generateGuide({ ...quotations[key], provider: key })}
                                    >
                                        <LocalShippingIcon />
                                    </IconButton>
                                </TableCell>
                            </StyledTableRow>
                        )
                    }) : null
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default QuotationList;