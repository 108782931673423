import React from 'react';
import {
    Table,
    Paper,
    TableBody,
    TableRow,
    TableHead,
    TableCell,
    IconButton,
    TableContainer,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { StyledTableCell, StyledTableRow } from '../shared';

const ProdsToShipList = ({ productsList, setValue, setOpenProdForm }) => {

    const removeProduct = (id) => {
        const newProducts = productsList.filter((prod) => prod.id !== id);
        setValue('prodsToShip', newProducts);
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} size='small' aria-label="Products to ship table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Venta producto</StyledTableCell>
                        <StyledTableCell>Producto</StyledTableCell>
                        <StyledTableCell>Pendiente de envío</StyledTableCell>
                        <StyledTableCell>Cantidad a enviar</StyledTableCell>
                        <StyledTableCell>Acciones</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {productsList ? productsList.map((prod) => (
                        <StyledTableRow key={prod.id}>
                            <TableCell>{prod.vpId}</TableCell>
                            <TableCell>{prod.prodName}</TableCell>
                            <TableCell>{prod.pendingShip}</TableCell>
                            <TableCell>{prod.shippingNow}</TableCell>
                            <TableCell>
                                <IconButton
                                    color='secondary'
                                    aria-label='delete product'
                                    onClick={() => removeProduct(prod.id)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                                <IconButton
                                    color='primary'
                                    aria-label='edit product'
                                    onClick={() => setOpenProdForm(prod.id)}
                                >
                                    <EditIcon />
                                </IconButton>
                            </TableCell>
                        </StyledTableRow>
                    )) : null}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default ProdsToShipList;