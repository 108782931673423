import * as Yup from 'yup';

export default Yup.object().shape({
    name: Yup.string()
        .max(100, 'Ingresa un valor valido, texto muy largo.!')
        .required('Este campo es requerido'),
    longShip: Yup.number()
        .typeError('Debe ser un número valido')
        .positive('El valor tiene que ser positivo')
        .required('Este campo es requerido'),
    widthShip: Yup.number()
        .typeError('Debe ser un número valido')
        .positive('El valor tiene que ser positivo')
        .required('Este campo es requerido'),
    heightShip: Yup.number()
        .typeError('Debe ser un número valido')
        .required('Este campo es requerido')
        .positive('El valor tiene que ser positivo'),
    weight: Yup.number()
        .typeError('Debe ser un número valido')
        .positive('El valor tiene que ser positivo')
        .required('Este campo es requerido'),
    qty: Yup.number()
        .typeError('Debe ser un número valido')
        .positive('El valor tiene que ser positivo')
        .required('Este campo es requerido')
});