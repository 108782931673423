import React from 'react'
import { Grid } from '@mui/material';
import { InputField } from '../inputs';

const AddressFields = ({ control }) => {
    return (
        <>
            <Grid container direction='row' spacing={2}>
                <Grid item>
                    <InputField
                        name="address.streetName"
                        label="Calle"
                        type="text"
                        fullwidth
                        required
                        control={control}
                    />
                </Grid>
                <Grid item>
                    <InputField
                        name='address.extNumber'
                        label='Número exterior'
                        type='text'
                        fullwidth
                        required
                        control={control}
                    />
                </Grid>
                <Grid item>
                    <InputField
                        name='address.intNumber'
                        label='Número interior'
                        type='text'
                        fullwidth
                        control={control}
                    />
                </Grid>
                <Grid item>
                    <InputField
                        name='address.colonyName'
                        label='Colonia'
                        type='text'
                        fullwidth
                        required
                        control={control}
                    />
                </Grid>
                <Grid item>
                    <InputField
                        name='address.zipCode'
                        label='Código postal'
                        type='text'
                        fullwidth
                        required
                        control={control}
                    />
                </Grid>
                <Grid item>
                    <InputField
                        name='address.city'
                        label='Ciudad'
                        type='text'
                        fullwidth
                        required
                        control={control}
                    />
                </Grid>
                <Grid item>
                    <InputField
                        name='address.state'
                        label='Estado'
                        type='text'
                        fullwidth
                        required
                        control={control}
                    />
                </Grid>
                <Grid item>
                    <InputField
                        name='address.references'
                        label='Referencias'
                        type='text'
                        fullwidth
                        control={control}
                    />
                </Grid>
                <Grid item>
                    <InputField
                        name='address.settlement'
                        label='Asentamiento'
                        type='text'
                        fullwidth
                        control={control}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default AddressFields;