import axios from 'axios';
import { API_URL } from '../config/config';

// TODO: Intercepción redundante
// replica las funciones default de axios

const onRequest = (config) => {
    return config;
};

const onRequestError = (error) => {
    return Promise.reject(error);
};

const onResponse = (response) => {
    return response;
};

const onResponseError = (error) => {
    return Promise.reject(error);
};

export const http = axios.create({
    baseURL: API_URL,
});

http.interceptors.request.use(onRequest, onRequestError);
http.interceptors.response.use(onResponse, onResponseError);
