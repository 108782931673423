import { routes } from '../config/routes';
import { Router, Route, Switch } from 'react-router-dom';
import Header from './Header';
import history from '../history';
import { Container } from '@mui/material';

export default function AppRouter() {
    return (
        <>
            <Header></Header>
            <Container maxWidth="lg" style={{ marginTop: 80 }}>
                <Router history={history}>
                    <Switch>
                        {routes.map(r => (
                            <Route key={r.path} exact path={r.path} component={r.component} />
                        ))}
                    </Switch>
                </Router>
            </Container>
        </>
    )
}
