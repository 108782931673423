import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";

export default function InputField({
    name,
    label,
    control,
    value,
    type,
    maxLength = 999,
    required,
    disabled,
    fullwidth,
    step = '1',
    autoFocus = false,
}) {
    if (!control) return <p>InputField {name}</p>

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState }) => {
                const errorMsg = fieldState.error?.message;
                const error = Boolean(fieldState.error?.message);

                return (
                    <TextField
                        type={type}
                        id={field.name}
                        name={field.name}
                        value={value ?? field.value}
                        disabled={disabled}
                        onChange={(e) => {
                            field.onChange(e);
                        }}
                        onBlur={field.onBlur}
                        ref={field.ref}
                        error={error}
                        step={step}
                        autoFocus={autoFocus}
                        helperText={error && errorMsg}
                        fullWidth={fullwidth}
                        multiline={type === 'textarea'}
                        rows="4"
                        label={label + (required ? '*' : '')}
                        inputProps={{ maxLength }}
                    />
                )
            }}
        />
    );
}