import React from 'react';
import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { StyledTableCell, StyledTableRow } from '../shared';

const InventoryList = ({ packagesList, setFieldValue, setOpenPkgForm }) => {

    const removePackage = (id) => {
        const newPackages = packagesList.filter((pkg) => pkg.id !== id);
        setFieldValue('packages', newPackages);
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="package table" size="small">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Contenido</StyledTableCell>
                        <StyledTableCell>Largo (cm)</StyledTableCell>
                        <StyledTableCell>Ancho (cm)</StyledTableCell>
                        <StyledTableCell>Alto (cm)</StyledTableCell>
                        <StyledTableCell>Peso (kg)</StyledTableCell>
                        <StyledTableCell>Volumen (m^3)</StyledTableCell>
                        <StyledTableCell># de cajas</StyledTableCell>
                        <StyledTableCell>Acciones</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {packagesList.map((pkg) => (
                        <StyledTableRow key={pkg.id}>
                            <StyledTableCell>{pkg.name}</StyledTableCell>
                            <StyledTableCell>{pkg.longShip}</StyledTableCell>
                            <StyledTableCell>{pkg.widthShip}</StyledTableCell>
                            <StyledTableCell>{pkg.heightShip}</StyledTableCell>
                            <StyledTableCell>{pkg.weight}</StyledTableCell>
                            <StyledTableCell>{pkg.vol}</StyledTableCell>
                            <StyledTableCell>{pkg.qty}</StyledTableCell>
                            <StyledTableCell>
                                <IconButton
                                    color='secondary'
                                    aria-label='delete package'
                                    onClick={() => removePackage(pkg.id)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                                <IconButton
                                    color='primary'
                                    aria-label='edit package'
                                    onClick={() => setOpenPkgForm(pkg.id)}
                                >
                                    <EditIcon />
                                </IconButton>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default InventoryList;