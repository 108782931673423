import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputField } from '../inputs';
import { packageValidator } from '../../models/validators';
import { getVolume } from '../../helpers';

export default function PackageForm({
    open,
    addPackage,
    onCancel,
    initialValues = undefined,
}) {
    const {
        control,
        reset,
        setValue,
        handleSubmit,
        formState: { isSubmitSuccessful }
    } = useForm({
        mode: 'onChange',
        shouldUnregister: false,
        defaultValues: initialValues ?? {
            name: '',
            longShip: 0,
            widthShip: 0,
            heightShip: 0,
            weight: 0,
            qty: 0,
        },
        resolver: yupResolver(packageValidator)
    });
    useEffect(() => {
        if (isSubmitSuccessful) {
            reset();
        }
    }, [isSubmitSuccessful, reset]);

    const { widthShip, longShip, heightShip } = useWatch({ control });

    useEffect(() => {
        let current = true;

        getVolume(widthShip, longShip, heightShip)
            .then(newVol => {
                if (!!current) {
                    setValue('vol', newVol);
                }
            });

        return () => {
            current = false;
        }
    }, [widthShip, longShip, heightShip, setValue])

    useEffect(() => {
        if (open && initialValues) {
            Object.keys(initialValues)
                .forEach(f => setValue(f, initialValues[f]))
        }
    }, [open, initialValues, setValue]);

    return (
        <Dialog open={open} onClose={onCancel}>
            <form onSubmit={handleSubmit(addPackage)} autoComplete="off">
                <DialogTitle>Agregar Paquete</DialogTitle>
                <DialogContent style={{ paddingTop: 10 }}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item>
                            <InputField
                                name="name"
                                label="Nombre"
                                type="text"
                                fullwidth
                                required
                                autoFocus
                                maxLength={50}
                                control={control}
                            />
                        </Grid>
                        <Grid item>
                            <InputField
                                name="longShip"
                                label="Largo (cm)"
                                type="number"
                                fullwidth
                                required
                                control={control}
                            />
                        </Grid>
                        <Grid item>
                            <InputField
                                name="widthShip"
                                label="Ancho (cm)"
                                type="number"
                                fullwidth
                                required
                                control={control}
                            />
                        </Grid>
                        <Grid item>
                            <InputField
                                name="heightShip"
                                label="Alto (cm)"
                                type="number"
                                fullwidth
                                required
                                control={control}
                            />
                        </Grid>
                        <Grid item>
                            <InputField
                                name="weight"
                                label="Peso (kg)"
                                type="number"
                                fullwidth
                                step="any"
                                required
                                control={control}
                            />
                        </Grid>
                        <Grid item>
                            <InputField
                                name="qty"
                                label="Número de Cajas"
                                type="number"
                                fullwidth
                                required
                                control={control}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { onCancel(); reset(); }}>Cancel</Button>
                    <Button type='submit'>{initialValues ? 'Guardar Cambios' : 'Agregar Paquete'}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
