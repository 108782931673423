import React from 'react';
import Add from '@mui/icons-material/Add';
import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles';
import theme from '../../theme';

const useStyles = makeStyles({
    root: {
        backgroundColor: theme.palette.secondary.light,
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
        },
    },
});

export default function AddButton({ onClick }) {
    const { root } = useStyles();

    return (
        <Button
            variant='contained'
            type='button'
            size='small'
            classes={{
                root
            }}
            startIcon={<Add />}
            onClick={onClick}
        >Agregar</Button>
    )
};
