import Home from '../pages/Home';
import GuideCreate from '../pages/GuideCreate';

export const routes = [
    {
        path: '/',
        component: Home,
        haveData: false,
    },
    {
        path: '/orders/:id/create-guide',
        component: GuideCreate,
        haveData: false,
    },
];
