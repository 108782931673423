import React, { useEffect } from 'react'
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { InputField } from '../inputs';

const validator = Yup.object().shape({
    shippingNow: Yup.number()
        .typeError('Debe ser un número valido')
        .positive('El valor debe ser positivo')
})

export default function EditProdToShipForm({
    open,
    onCancel,
    saveProdToShip,
    initialValues = undefined,
}) {
    const {
        control,
        setValue,
        handleSubmit,
    } = useForm({
        mode: 'onChange',
        shouldUnregister: false,
        defaultValues: initialValues ?? {
            shippingNow: '',
        },
        resolver: yupResolver(validator)
    });

    useEffect(() => {
        if (open && initialValues) {
            Object.keys(initialValues)
                .forEach(f => setValue(f, initialValues[f]))
        }
    }, [open, initialValues, setValue]);

    return (
        <Dialog open={open} onClose={onCancel}>
            <form onSubmit={handleSubmit(saveProdToShip)} >
                <DialogTitle>Editar cantidad a enviar</DialogTitle>
                <DialogContent style={{ paddingTop: 10 }}>
                    <Grid container direction='column' spacing={1}>
                        <Grid item>
                            <InputField
                                name='shippingNow'
                                label='Catidad a enviar'
                                type='number'
                                autoFocus
                                control={control}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { onCancel(); }}>Cancel</Button>
                    <Button type='submit'>Guardar Cambios</Button>
                </DialogActions>
            </form >
        </Dialog>
    );
}