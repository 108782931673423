import { http } from '../utils/http';

const OrderAPI = {
    async getOrder(id) {
        try {
            const response = await http.request({
                url: `/orders/${id}`,
                method: 'GET',
            });

            return response.data;
        } catch (error) {
            return null;
        }
    },
}

export default OrderAPI;